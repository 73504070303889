import * as Sentry from '@sentry/browser';
import { browserTracingIntegration } from '@sentry/browser';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://a85e8b63b29e4a7e952f9f9b6fbf8781@o333864.ingest.us.sentry.io/4503894151004160',
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    sampleRate: 0.25,

    beforeSend(event) {
      const filteredReaErrors =
        event.exception?.values?.filter((err) => err.type === 'ReaError') ||
        undefined;

      const eventOrNull: Sentry.ErrorEvent | null =
        filteredReaErrors && filteredReaErrors.length > 0
          ? {
              ...event,
              exception: {
                ...event.exception,
                values: filteredReaErrors,
              },
            }
          : null;

      return eventOrNull || null;
    },
  });
};
